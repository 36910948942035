export default {
  data() {
    return {
      data: window.data,
      expand: {
        navbar: false,
        platformRating: false,
        fundRating: false
      },
      open: false,
      loggedIn: false,
      user: window.user
    }
  },
  mounted() {
    if(window['lazyLoadInstance']) {
      window.lazyLoadInstance.update()
    }

    if(window['lazyLoadFooter']) {
      window.lazyLoadFooter()
    }

    if(window['lazyLoadInstance']) {
      window.lazyLoadInstance.update()
    }

    document.body.classList = `${document.body.classList} ${this.user.guest ? 'user-guest' : 'user-loggedin'}`
  },
  provide: {
    data: window.data,
    params: window.data.params,
    user: user
  },
  methods: {
  	toggle(key) {
      if(key == 'navbar') {
        this.expand.navbar = !this.expand.navbar;
      } else if(key == 'platformRating') {
        this.expand.platformRating = !this.expand.platformRating
      } else if(key == 'fundRating') {
        this.expand.fundRating = !this.expand.fundRating
      }
    },
    showLogin() {
      this.$store.dispatch('app/showLogin')
    },
    slideToggle(selector) {
      var el = document.querySelector(selector);
      if(el.classList.contains('hidden')) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    },
    tabToggle(event) {
      // Make the other tabs not active
      var tabLinks = event.target.closest('.tab--list').querySelectorAll('.tab--link');
      for(var i=0; i<tabLinks.length; i++) {
        tabLinks[i].classList.remove('tab--active');
      }

      // Make the clicked tab active
      event.target.classList.add('tab--active');

      // Add hidden class to all tabs
      var tab = document.querySelector(event.target.dataset.tab);
      var tabContents = tab.closest('.tabs').querySelectorAll('.tab')
      for(var i=0; i<tabContents.length; i++) {
        tabContents[i].classList.add('hidden');
      }

      // Remove hidden class from the clicked tab
      if(tab.classList.contains('hidden')) {
        tab.classList.remove('hidden')
      } else {
        tab.classList.add('hidden')
      }
    }
  }
}
