import Spark from '../spark'

class SparkElement {
  constructor(el, key, data, context, options) {
    this.el = el
    this.key = key
    this.context = context
    this._data = data
    this.options = options || {}
    this.namespace = this.options['namespace']
    this.formats = []
    this.watchers = []

    if(this.options.format) {
      var elementFormats = this.options.format.split(',')
      for(var format in elementFormats) {
        this.formats.push(Spark.format(elementFormats[format]))
      }
    } else {
      // default to a formatter that returns whatever the value is
      this.formats = [(input) => input]
    }

    // If this field is changed from another instance of this variable, sync it here
    this.watchField(key, (newValue) => {
      if(this._data != newValue) {
        this._data = newValue
        this.update()
      }
    })

    this.bind()
  }

  get data() {
    return this._data
  }

  set data(newData) {
    if(this._data != newData) {
      this._data = newData
      this.dispatchChange()
    }
  }

  dispatchChange() {
    this.context.$store.dispatch('interactive/setGuideField', {
      namespace: this.namespace,
      field: this.key,
      value: this._data
    })
  }

  // Called during instantiation for creating all listeners
  bind() {
    this.update()
  }

  // Generally called whenever data changes to update the layout
  update() {
    console.log('`update` is not implemented in InteractiveElement base class. Override in subclass.')
  }

  watchField(field, callback) {
    var newWatcher = this.context.$store.watch(this.createFieldWatcher(field), callback, { deep: true });
    this.watchers.push(newWatcher)
  }

  createFieldWatcher(key) {
    return (state) => {
      return state.interactive[this.namespace][key]
    }
  }


  // Called when the directive is unloaded. Remove all DOM listeners here.
  unbind() {
    for(var watcher of this.watchers) {
      watcher()
    }
    this.unbindAll()
  }

  // Default to no-op
  unbindAll() {}

}

export default SparkElement
