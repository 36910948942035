import { every } from '~/app/utils/every'

export default class TempGuide {
  constructor(guide, updateFields) {
    // Generate a new key for this instance
    this.key = `temp-guide-${parseInt(Math.random()*1000000000)}`
    this.updateFields = updateFields
    this.guide = guide
    this.guide.temp = true
    this.updating = false
    this.initialPath = window.location.pathname

    window.tempwatchers ||= {}

    // Set initial state based on defaults and computed fields
    window.store.dispatch('interactive/setGuideFields', { namespace: this.key, guide: updateFields(this.guide) }).then(() => {
      // Create a watcher for any fields that change
      window.tempwatchers[this.key] = window.store.watch(this.watchedFields.bind(this), this.stateChanged.bind(this), { deep: true })
    })
  }

  watchedFields(state) {
    return state.interactive[this.key]
  }

  stateChanged(oldGuide, guide) {
    // debugger
    if(this.updating) { return true }
    this.updating = true

    var changedGuide = this.updateFields(guide)
    if(every(changedGuide, (x) => { return x !== undefined})) {
      window.store.dispatch('interactive/setGuideFields', { namespace: this.key, guide: changedGuide }).then(() => {
        this.updating = false
      })
    } else {
      if(window.location.pathname == this.initialPath) {
        alert('Ack, something went wrong with our calculations that caused some errors. I\'ve reset your settings to keep things working. Sorry about that!')
      } else {
        window.tempwatchers[this.key]()
      }
    }
  }
}
