<template>
  <div class='post--content'>
    <slot :guide='interactive[manager]'
          :service='service'>
    </slot>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Manager from '~/app/managers/manager'
import Post from './post.vue'

export default {
  name: 'interactive-post',
  expends: Post,
  props: ['manager'],
  computed: {
    ...mapFields({
      interactive: 'interactive'
    })
  },
  data() {
    return {
      service: null
    }
  },
  beforeMount() {
    var newClass = Manager.manager(this.manager)
    this.service = new newClass(this.$store)
  }
}
</script>
