import SparkElement from './spark-element'

class SparkToggle extends SparkElement {
  bind() {
    this.addListeners()
    this.update()
  }

  addListeners() {
    this.el.addEventListener('click', this.toggleData.bind(this))
    this.el.addEventListener('click', this.update.bind(this))
  }

  toggleData() {
    this._data = !this._data
  }

  update() {
    if(this._data) {
      this.el.children[0].classList.remove('hidden')
      this.el.children[1].classList.add('hidden')
    } else {
      this.el.children[0].classList.add('hidden')
      this.el.children[1].classList.remove('hidden')
    }
  }

  unbindAll() {
    this.el.removeEventListener('click', this.toggleData.bind(this))
    this.el.removeEventListener('click', this.update.bind(this))
  }
}

export default SparkToggle
