import * as d3 from '~/app/custom-d3'

export default class SpViz {
  constructor(el, options) {
    this.el = el
    this.currentStep = 0

    this.images = [
      'http://localhost:3000/images/step1.png',
      'http://localhost:3000/images/step1.png',
      'http://localhost:3000/images/step3.png',
      'http://localhost:3000/images/step4.png',
      'https://via.placeholder.com/1050x750&text=step4',
      'https://via.placeholder.com/1050x750&text=step5',
      'https://via.placeholder.com/1050x750&text=step6',
      'https://via.placeholder.com/1050x750&text=step7',
      'https://via.placeholder.com/1050x750&text=step8',
      'https://via.placeholder.com/1050x750&text=step9',
      'https://via.placeholder.com/1050x750&text=step10',
      'https://via.placeholder.com/1050x750&text=step11',
      'https://via.placeholder.com/1050x750&text=step12'
    ]

    // this.steps = [
    //   { enter: this.step1, exit: this.step1Exit },
    //   { enter: this.step2, exit: this.step2Exit }
    // ]

    // this.data = {}
    // this.data.sectors = [
    //   { sector: 'Consumer Discretionary', color: 'red', weight: 10.5 },
    //   { sector: 'Communication Services', color: 'blue', weight: 10.8 },
    //   { sector: 'Consumer Staples', color: '#1aa093', weight: 7.4 },
    //   { sector: 'Energy', color: 'steelblue', weight: 3 },
    //   { sector: 'Financial Services', color: 'weight', weight: 10.6 },
    //   { sector: 'Health Care', color: 'green', weight: 15.4 },
    //   { sector: 'Industrials', color: 'yellow', weight: 7.9 },
    //   { sector: 'Materials', color: 'brown', weight: 2.5 },
    //   { sector: 'Real Estate', color: 'salmon', weight: 2.9 },
    //   { sector: 'Technology', color: '#9f7aea', weight: 25.7 },
    //   { sector: 'Utilities', color: '#777', weight: 3.3 }
    // ]

    // this.calculateSize()
    this.setup()
    this.resize()
  }

  noop() {}

  // Create all elements that will be reused
  setup() {

    this.images = this.el.selectAll('.step--image')
      .data(this.images)
      .enter()
      .append('img')
        .attr('src', (d) => d)
        .attr('id', (d, i) => `step--image-${i}`)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .style('opacity', 0)

    // Step 1
    // this.titleFontSize = 48
    // this.spCircle = this.svg
    //   .selectAll('.sp-circle--circle')
    //   .data(['The S&P 500'])
    //   .enter()
    //   .append('circle')
    //     .attr('class', 'sp')
    //     .attr('stroke', '#555')
    //     .attr('fill', 'white')
    // this.spText = this.svg
    //   .selectAll('.sp-circle--text')
    //   .data(['The S&P 500'])
    //   .enter()
    //   .append('text')
    //     .text((d) => d)
    //     .attr('dominant-baseline', 'middle')
    //     .attr('text-anchor', 'middle')


    // Step 2
    // Arrowhead
    // this.arrowHead = this.svg.append("defs").append("marker")
    // this.arrowHead.attr('id', 'arrowhead')
    //   .attr('class', 'defs--grey')
    //   .attr("viewBox", "0 0 10 10" )
    //   .attr('refX', 8)
    //   .attr('refY', 5)
    //   .attr('markerWidth', 12)
    //   .attr('markerHeight', 32)
    //   .attr('markerUnits', 'userSpaceOnUse')
    //   .attr('stroke', 'none')
    //   .attr('orient', 'auto')
    //   .append('path').attr('d', 'M 0 0 L 10 5 L 0 10 z')
    // this.arrow = this.svg.append("path")
    //               .attr('class', 'line line--sm stroke-grey-500')
    //               .attr('stroke-width', 2)
    //               .attr('stroke-linecap', 'round')
    //               .attr('fill', 'none')
    //               .style('opacity', 0)
    //
    // this.sectorsGroup = this.svg.append('g')
    // this.sectors = this.sectorsGroup.selectAll('.sp-circle--sector')
    //   .data(this.data.sectors)
    //   .enter()
    //   .append('circle')
    //     .attr('class', 'sp-circle--sector')
    //     .style('opacity', 1)
    //     .attr('fill', (d) => d.color)
    //     .attr('r', 10)
  }

  calculateSize() {
    this.height = window.innerHeight * 0.8;
    var figureMarginTop = (window.innerHeight - this.height) / 2;

    this.el
      .style("height", this.height + "px")
      .style("width", '100%')
      .style("top", figureMarginTop + "px");

    var bounds = this.el.node().getBoundingClientRect()
    this.width = bounds.width
    this.min = d3.min([this.height, this.width])
  }

  sectorTick() {
    console.log('sectorTick')
    d3.selectAll(".sp-circle--sector")
      .attr("cx", (d) => { return d.x })
      .attr("cy", (d) => d.y)
  }
  resize() {
    this.calculateSize()

    // let marketcapDomain = d3.extent(this.data.sectors.map((d) => d.weight));
    // this.size = d3.scaleLinear().domain(marketcapDomain).range([30, this.height/4]);
    // this.simulation = d3
    //   .forceSimulation(this.data.sectors, marketcapDomain)
    //   .force("x", d3.forceX((d) => this.width/2).strength(0.8))
    //   .force("y", d3.forceY((d) => this.height/2).strength(0.8))
    //   .force("collide", d3.forceCollide((d) =>  this.size(d.weight)))
    //   .alphaDecay(0)
    //   .alpha(0.3)

    // Redraw the current step
    this.enter(this.currentStep, 'resize')
  }

  // calcSlope(p1, p2) {
  //   let slope = Math.atan( (p2.y - p1.y)/(p2.x - p1.x) ) * 180/Math.PI
  //
  //   // account for negative angles
  //   if((slope <= 0 && (p2.x - p1.x) < 0) || (slope > 0 && (p2.x - p1.x) < 0)){
  //     slope += 180
  //   }
  //   return slope
  // }

  enter(index, direction) {
    this.currentStep = index

    d3.selectAll(`#step--image-${index}`)
      .transition().duration(500).delay(100)
      .attr('class', 'step--image-active')
      .style('opacity', 1)

    // if(this.steps[index] && this.steps[index]['enter']) {
    //   this.steps[index].enter.call(this, direction)
    // }
  }
  exit(index, direction) {
    // if(this.steps[index] && this.steps[index]['exit']) {
    //   this.steps[index].exit.call(this, direction)
    // }

    if(index == 0 && direction == 'up') { return }

    d3.selectAll(`.step--image-active`)
      .transition().duration(500).delay(100)
      .style('opacity', 0)
  }



  // Step 1: Draw a circle representing the S&P 500
  // step1(direction) {
  //   console.log('step1 - enter')
  //   this.spCircle
  //       .transition().duration(500).delay(100)
  //       .attr('r', (this.min*0.7)/2)
  //       .attr('cx', this.width/2)
  //       .attr('cy', this.height/2)
  //       .attr('stroke', '#555')
  //       .attr('stroke-width', '3px')
  //       .attr('fill', 'white')
  //
  //   this.spText
  //     .transition().duration(500).delay(100)
  //     .style('opacity', 1)
  //     .attr('x', this.width/2)
  //     .attr('y', this.height/2)
  //     .attr('font-size', `${this.titleFontSize}px`)
  // }
  // step1Exit(direction) {
  //   console.log('step1 - exit')
  // }


  // Step 2: Move to the
  // step2() {
  //   console.log('step2 - enter')
  //
  //   var r = (this.min*0.95)/2,
  //       y = this.height/2 - r
  //
  //   var xCenter = this.width/2,
  //       yCenter = (this.height + y) / 2
  //   this.spCircle
  //       .transition().duration(500).delay(100)
  //       .attr('r', r)
  //       .attr('cx', xCenter)
  //       .attr('cy', yCenter)
  //   this.spText
  //     .transition().duration(500)
  //     .attr('y', this.titleFontSize)
  //     .attr('x', this.width - 20 - this.spText.node().getClientRects()[0].width/2)
  //     .attr('font-size', `${this.titleFontSize/1.5}px`)
  //     .on("end", function() {
  //       console.log("Done moving the text")
  //       // Draw arrow from "The S&P 500" to the circle
  //       var textNodeRect = this.spText.node().getClientRects()[0]
  //       var arrowStartY = +this.spText.attr('y') + 25,
  //           arrowStartX = +this.spText.attr('x'),
  //           arrowEndX = +this.spCircle.attr('cx') + +this.spCircle.attr('r') - 20,
  //           arrowEndY = +this.spCircle.attr('cy') / 2,
  //           arrowPath = d3.path(),
  //           cX = (arrowEndX + arrowStartX)/2*1.1,
  //           cY = (arrowEndY + arrowStartY)/2*1.25
  //       arrowPath.moveTo(arrowStartX, arrowStartY)
  //       arrowPath.bezierCurveTo(arrowStartX, arrowStartY, cX, cY, arrowEndX, arrowEndY)
  //       this.arrow.attr("d", arrowPath.toString())
  //
  //
  //       let pathLength = this.arrow.node().getTotalLength()
  //       let pathNode = this.arrow.node()
  //       let p1 = pathNode.getPointAtLength(pathLength - 20)
  //       let p2 = pathNode.getPointAtLength(pathLength)
  //       this.arrowHead.attr('orient', this.calcSlope(p1, p2))
  //       this.arrow.attr('marker-end', 'url(#arrowhead)')
  //                 .transition().duration(500)
  //                 .style('opacity', 1)
  //     }.bind(this))
  //
  //     this.sectors
  //       .transition().duration(500).delay(100)
  //       .style('opacity', 1)
  //       .attr('r', (d) => this.size(d.weight))
  //       .attr('cx', xCenter)
  //       .attr('cy', yCenter);
  //
  //     this.simulation.on("tick", this.sectorTick);
  //     this.simulation.alphaDecay(0.1);
  // }
  // step2Exit() {
  //   this.arrow.transition().duration(500).style('opacity', 0)
  // }
}

// SP 500 Viz
// @import '~animate.css/source/attention_seekers/bounce'
