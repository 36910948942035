<template>
  <div class="dropdown--about hidden relative bg-gray-100 md:bg-white md:shadow-lg md:rounded md:mt-1">
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-white triangle hidden md:block'></icon>
    <ul class="list-reset">
      <li class="border-b">
        <a class="dropdown--link" href="/about">About Minafi &amp; Adam</a>
      </li>
      <li class="border-b">
        <a class="dropdown--link" href="/goals">Personal Goals</a>
      </li>
      <li class="border-b">
        <a class="dropdown--link" href="/recommendations">Recommendations</a>
      </li>
      <li class="border-b">
        <a class="dropdown--link" href="/vision">Minafi's Vision &amp; Mission</a>
      </li>
      <li class="border-b">
        <a class="dropdown--link" href="/income">Minafi Income &amp; Expenses</a>
      </li>
      <li class="border-b">
        <a class="dropdown--link" href="/contact">Contact Me</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '~/components/utils/icon.vue'
export default {
  components: {
    Icon
  }
}
</script>
