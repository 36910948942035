import SparkElement from './spark-element'

class SparkInput extends SparkElement {
  bind() {
    this.createInputElement()
    this.addChangeListeners(this.input)
    this.syncSize()
  }

  createInputElement() {
    this.input = document.createElement('input')
    this.setupElement(this.input)
    if(this.options.size) {
      this.input.size = this.options.size
    }
    this.input.value = this._data

    this.el.append(this.input)
  }

  setupElement(input) {
    input.type = 'text'
  }

  addChangeListeners(inputEl) {
    inputEl.addEventListener('keyup',  this.dataChanged.bind(this))
    inputEl.addEventListener('blur',   this.dataChanged.bind(this))
    inputEl.addEventListener('change', this.dataChanged.bind(this))
  }

  dataChanged() {
    this.syncData()
    this.syncSize()
  }

  syncData() {
    this.data = this.input.value
  }

  syncSize() {
    if(this.options.autogrow) {
      this.input.size = this.input.value.length + 1
    }
  }

  update() {
    this.input.value = this._data
  }

  unbindAll() {
    this.input.removeEventListener('keyup',  this.dataChanged.bind(this))
    this.input.removeEventListener('blur',   this.dataChanged.bind(this))
    this.input.removeEventListener('change', this.dataChanged.bind(this))
  }
}

export default SparkInput
