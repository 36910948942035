function VLightbox(el, binding, vnode) {
  var container;
  el.classList.add('cursor-pointer')

  el.addEventListener('click', (event) => {
    var bg = document.createElement('div')
    bg.classList = 'bg-smoke-700 fixed h-full w-full inset-0 top-0 z-100 transition-opacity opacity-0 duration-150 transform'
    bg.addEventListener('click', (e) => {
      bg.classList.remove('opacity-100')
      bg.classList.add('opacity-0')
      setTimeout(() => bg.parentNode.removeChild(bg), 150)
    })

    container = document.createElement('div')
    container.classList = 'container flex flex-col items-center my-8 lg:my-16'

    // Close button
    var a = document.createElement('a')
    a.appendChild(document.createTextNode("close"))
    a.classList = 'block self-end text-right underline text-gray-100 cursor-pointer mr-4'
    container.append(a)

    // Create the image
    var img = document.createElement('img')
    img.src = binding.value
    img.classList = 'flex-none max-w-full'
    container.append(img)

    // Add the title if there is one
    if(el.getAttribute('alt') && (el.getAttribute('alt').length > 0)) {
      var p = document.createElement('p')
      p.appendChild(document.createTextNode(el.getAttribute('alt')))
      p.classList = 'text-gray-200 mt-4 font-semibold text-lg'
      container.append(p)
    }


    bg.append(container)
    document.body.appendChild(bg)

    setTimeout(() => bg.classList.add('opacity-100'), 50)
  })
}

export default VLightbox
