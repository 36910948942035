import { getCookie } from '~/app/cookie'

const user = JSON.parse(getCookie('user') || "{}")

function VGuest(el, binding, vnode) {
  if(binding.value) {
    // Show for guests, hide for everyone else
    if(!user.guest) {
      el.classList.add('hidden-z')
    }
  } else {
    // Hide for guests, hide for everyone else
    if(user.guest) {
      el.classList.add('hidden-z')
    }

  }
}

export default VGuest
