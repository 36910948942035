<template>
  <div class='dropdown--user bg-gray-100 md:shadow-lg md:rounded md:mt-1 md:bg-white'>
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-white triangle hidden md:block'></icon>
    <ul class='list-reset'>
      <li class='border-b' v-if='user.unread_notifications_count > 0'>
        <a href='/account/notifications' class='dropdown--link'>Notifications</a>
      </li>
      <li class='border-b'>
        <a href='/account/dashboard' class='dropdown--link'>Dashboard</a>
      </li>
      <li class='border-b'>
        <a href='/users/edit' class='dropdown--link'>Account Settings</a>
      </li>
      <li class='border-b'>
        <form class="button_to" method="post" action="/users/sign_out">
          <input type="hidden" name="_method" value="delete">
          <input class="block py-2 px-4 text-blue-500 hover:no-underline font-semibold py-2 px-4 bg-gray-100 hover:underline hover:bg-blue-100 w-full text-left md:bg-white" aria-label="Log Out" type="submit" value="Log out">
        </form>
      </li>
    </ul>

    <p class='hidden py-2 px-4 bg-gray-100 text-sm md:block'>
      Logged in as <b>{{user.name}}</b>.
    </p>
  </div>
</template>


<script>
import Icon from '~/components/utils/icon.vue'
export default {
  inject: ['user'],
  components: {
    Icon
  }
}
</script>
