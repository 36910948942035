import VGuest from '~/directives/guest'
import VLightbox from '~/directives/lightbox'
import VRefreshImages from '~/directives/refreshImages'
import VTooltip from '~/directives/tooltip'

export default {
  guest: VGuest,
  lightbox: VLightbox,
  refreshImages: VRefreshImages,
  tooltip: VTooltip
}
