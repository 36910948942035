<template>
  <div class="dropdown--invest relative bg-green-100 rounded md:shadow-lg md:mt-1 relative">
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-green-100 triangle hidden md:block'></icon>
    <div class="mb-2 md:px-4 md:py-2">
      <p class="p-4 pb-2 flex flex-row justify-between">
        <span class="text-green-500 uppercase text-sm tracking-normal font-bold">
          Free Courses
        </span>
        <a class="text-green-100 bg-green-400 rounded text-sm tracking-normal px-2 hover:bg-green-500" href='/bootcamp'>See all</a>
      </p>
      <ul class="list-reset">
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href='/courses/the-minimal-investor'>
            <icon icon='acorn-duotone' class='w-12 h-12 mr-3 text-green-500'></icon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-green-800">
                The Minimal Investor
              </p>
              <p class="text-gray-800 text-sm font-400 mb-2">Learn the core concepts of investing.</p>
              <p class="text-gray-700 text-xs font-400 flex-1 flex-row">
                <span class="mr-2">
                  <minicon icon='book-regular' size='4' class='text-green-500'></minicon>
                  10 Lessons
                </span>
                <span class="mr-2">
                  <span class="relative w-4 h-4 inline-block -mb-1">
                    <minicon icon='signal-alt-3-light' size='4' class='text-green-500 absolute bottom-0 left-0'></minicon>
                    <minicon icon='signal-1-solid' size='4' class='text-green-600 absolute bottom-0 left-0'></minicon>
                  </span>
                  Basic
                </span>
                <span>
                  <minicon icon='clock-regular' size='4' class='text-green-500'></minicon>
                  2h 30m
                </span>
              </p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="relative">
      <svg class="absolute" preserveAspectRatio="none" viewBox="0 0 2600 131.1">
        <path d="M0 0L2600 0 2600 100 0 0z" fill="#f0fff4"></path>
      </svg>
      <div class="border-t rounded-t-none border-gray-400 bg-blue-200 md:px-4 md:py-2 rounded text-left">
        <p class="p-4 pb-2 flex flex-row justify-between">
          <span class="text-blue-600 uppercase text-sm tracking-normal font-bold">
            Members Only
          </span>
          <a class="text-blue-100 bg-blue-400 rounded text-sm tracking-normal px-2 hover:bg-blue-500">Start for Free</a>
        </p>
        <ul class="list-reset flex flex-col">
          <li class="flex-1">
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href='/bootcamp'>
              <icon icon='seedling-duotone' class='w-12 h-12 mr-3 text-blue-500'></icon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-blue-800">Minafi Investor Bootcamp</p>
                <p class="text-gray-800 text-sm font-400 mb-2">Learn how to grow your investments confidently in just 3 months.</p>
                <p class="text-gray-700 text-xs font-400 flex-1 flex-row">
                  <span class="mr-2">
                    <minicon icon='book-regular' size='4' class='text-blue-500'></minicon>
                    59 Lessons
                  </span>
                  <span class="mr-2">
                    <span class="relative w-4 h-4 inline-block -mb-1">
                      <minicon icon='signal-3-solid' size='4' class='text-blue-600 absolute bottom-0 left-0'></minicon>
                    </span>
                    Advanced
                  </span>
                  <span>
                    <minicon icon='clock-regular' size='4' class='text-blue-500'></minicon>
                    15h 50m
                  </span>
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Minicon from '~/components/utils/minicon.vue'
import Icon from '~/components/utils/icon.vue'
export default {
  components: {
    Icon,
    Minicon
  }
}
</script>
