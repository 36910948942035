import { every } from '~/app/utils/every'
import { isEmpty } from '~/app/utils/isEmpty'

const currentMonth = new Date().getMonth()

let defaultGuide = {
  // 1
  exampleClick: false,
  age: 30,
  ageMonths: 0,
  yearlyIncome: 50000,
  yearlySavings: 10000,
  marketGrowth: 0.07,
  marketGrowthAfterInflation: 0.07-0.02,
  monthBorn: 0,
  inflationRate: 0.02,

  // 1-calculated
  savingsRate: 0.20,

  // 3
  gender: 'undisclosed',
  race: 'undisclosed',
  phase: 1,
  childrenCount: 0,
  goalReAge: 50,
  networth: 5000,
  fiStash: 1000000,

  // 4
  wr: 0.04,

  storeCookies: true,
  emailDownload: 0,
  yearlySpending: 40000,
  retirementSpendingPercent: 0.8,
  spendingReductionPercent: 0.10,
  eirIncomePercent: 0.10,
  monthlyIncome: 0,
  retirementMonthlyIncome: 0,
  payIncreasePercent: 0.02
}

function updateFields(guide) {
  // Part 1 - About
  guide.nextAge = guide.age + 1
  guide.savingsRate = guide.yearlySavings / guide.yearlyIncome
  guide.monthsUntilBirthday = (currentMonth > guide.monthBorn) ? (12 + +guide.monthBorn - currentMonth) : (+guide.monthBorn - currentMonth)
  guide.ageMonths = 11 - guide.monthsUntilBirthday
  guide.ageFull = guide.age + (guide.ageMonths/12)

  // t.stop()
  return guide
}

export default class InvestGuide {
  constructor(post) {
    this.post = post
    this.updating = false
    window.investWatcher = this.post.$store.watch(this.watchedFields, this.stateChanged, { deep: true })

    if(isEmpty(window.data.interactive.invest)) {
      // Trigger initial state through changing the fields
      this.reset()
    }
    else {
      // Manually trigger state change with current state
      this.stateChanged(null, this.post.$store.getters['interactive/getGuideFields']('invest'))
    }
  }

  reset() {
    var currentGuide = this.post.$store.getters['interactive/getGuideFields']('invest')
    for(var key in defaultGuide) {
      currentGuide[key] = defaultGuide[key]
    }

    this.stateChanged(null, updateFields(currentGuide))
  }

  watchedFields(state) {
    return state.interactive.invest
  }

  stateChanged(oldGuide, guide) {
    if(this.updating) { return true }
    this.updating = true

    var changedGuide = updateFields(guide)
    if(every(changedGuide, (x) => { return x!== undefined})) {
      window.store.dispatch('interactive/setGuideFields', { namespace: 'invest', guide: changedGuide }).then(() => {
        this.updating = false
      })
    } else {
      if(window.location.pathname == 'how-to-invest') {
        alert('Ack, something went wrong with our calculations that caused some errors. I\'ve reset your settings to keep things working. Sorry about that!')
        this.reset()
      } else {
        // unwatch
        window.investWatcher()
      }
    }
  }
}
