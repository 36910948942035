<template>
  <div>
    <slot
      :toggleSidebar='toggleSidebar'
      :showLogin='showLogin'
    ></slot>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields({
      expanded: 'app.sidebarExpanded'
    })
  },
  methods: {
    showLogin() {
      this.$store.dispatch("app/showLogin")
    },
    toggleSidebar() {
      this.$store.dispatch('app/toggleSidebar')
    }
  }
}
</script>



<style>
body {
  transition: margin-left 0.3s;
}
</style>
