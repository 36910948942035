<template>
  <div class="dropdown--fire bg-orange-100 relative rounded md:shadow-lg md:mt-1">
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-orange-100 triangle hidden md:block'></icon>
    <div class="md:px-4 md:py-2">
      <div class="text-orange-500 text-sm tracking-normal p-4 pb-2 md:px-4 md:py-4 no-underline" href="/interactive-guide-early-retirement-financial-independence">
        <b>FIRE:</b>
        Financial Independence, Retire Early
      </div>
      <ul class="list-reset">
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/interactive-guide-early-retirement-financial-independence">
            <minicon icon='fire-regular' size='6' class='mr-3 text-orange-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-orange-800 pb-1">
                Interactive Guide to FIRE
                <span class="rounded text-xs bg-orange-400 text-white px-1 uppercase font-bold tracking-tighter hidden md:inline-block ml-1">Start here</span>
              </p>
              <p class="text-orange-700 text-sm font-400">Your portal into the FIRE movement</p>
            </div>
          </a>
        </li>
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/categories/personal">
            <minicon icon='pencil-regular' size='6' class='mr-3 text-orange-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-orange-800 pb-1">My FIRE Story</p>
              <p class="text-orange-700 text-sm font-400">I retired at 36. Follow my story here</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="relative">
      <svg class="absolute text-orange-100" preserveAspectRatio="none" viewBox="0 0 2600 131.1">
        <path d="M0 0L2600 0 2600 100 0 0z" fill="currentColor"></path>
      </svg>
      <div class="px-3 pb-2 pt-4 bg-orange-200 mb-2 rounded">
        <p class="p-4 pb-2 flex flex-row justify-between">
          <span class="text-orange-500 uppercase text-sm tracking-normal font-bold">
            Beyond Minafi
          </span>
        </p>
        <ul class="list-reset">
          <li>
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/news/fire">
              <minicon icon='newspaper-regular' size='6' class='mr-3 text-orange-500'></minicon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-orange-800 pb-1">FIRE in the News</p>
                <p class="text-orange-700 text-sm font-400">Articles from major publications</p>
              </div>
            </a>
          </li>
          <li>
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/blogs#fire">
              <minicon icon='blog-regular' size='6' class='mr-3 text-orange-500'></minicon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-orange-800 pb-1">FIRE Blogs</p>
                <p class="text-orange-700 text-sm font-400">My favorite blogs by others</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import Minicon from '~/components/utils/minicon.vue'
import Icon from '~/components/utils/icon.vue'
export default {
  components: {
    Icon,
    Minicon
  }
}
</script>
