import * as client from '~/app/client'

// actions
const actions = {
  get({commit, rootState}, {id}) {
    return client.get(`/comments/${id}.json`)
                 .then((response) => {
      return response.comment
    }).catch((error) => {
      return Promise.reject(new Error('Could not find the comment'))
    })
  },

  markAsSpam({commit, rootState}, {id}) {
    return client.put(`/comments/${id}`, { comment: { state: 'spam'}})
      .then((response) => response)
  },

  delete({commit, rootState}, {id}) {
    return client.remove(`/comments/${id}`)
      .then((response) => response)
  },

  create({commit, rootState}, {comment, commentable}) {
    return client.post('/comments', { comment: comment, commentable: commentable })
      .then((response) => response)
  },

  update({commit, rootState}, {comment}) {
    return client.put(`/comments/${comment.id}`, { comment: comment })
      .then((response) => response)
  }
}

export default {
  namespaced: true,
  actions
}
