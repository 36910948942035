<template>
  <div class="dropdown--interactive bg-purple-100 relative rounded md:shadow-lg md:mt-1">
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-purple-100 triangle hidden md:block'></icon>
    <div class="mb-2 md:px-4 md:py-2">
      <p class="p-4 pb-2 flex flex-row justify-between">
        <span class="text-purple-500 uppercase text-sm tracking-normal font-bold">
          Popular Posts
        </span>
        <a class="text-purple-100 bg-purple-400 rounded text-sm tracking-normal px-2 hover:bg-purple-500" href='/calculators'>See all</a>
      </p>
      <ul class="list-reset">
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/interactive-guide-early-retirement-financial-independence">
            <minicon icon='fire-regular' size='6' class='mr-3 text-purple-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-purple-800 pb-1">
                Interactive Guide to FIRE
              </p>
              <p class="text-orange-700 text-sm font-400">What you need to know about FIRE</p>
            </div>
          </a>
        </li>
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/diversification">
            <minicon icon='shopping-basket-regular' size='6' class='mr-3 text-purple-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-purple-800 pb-1">Interactive Guide to Diversification</p>
              <p class="text-gray-800 text-sm font-400">Don't put all your eggs in one basket</p>
            </div>
          </a>
        </li>
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/periodic-table-of-fire">
            <minicon icon='table-light' size='6' class='mr-3 text-purple-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-purple-800 pb-1">Periodic Table of FIRE</p>
              <p class="text-gray-800 text-sm font-400">The 118 elements of FIRE</p>
            </div>
          </a>
        </li>
        <li>
          <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/gender-pay-gap">
            <minicon icon='chart-bar-regular' size='6' class='mr-3 text-purple-500'></minicon>
            <div class="flex-1 flex flex-col p-1">
              <p class="text-purple-800 pb-1">Gender Pay Gap Data Exploration</p>
              <p class="text-gray-800 text-sm font-400">Results from 10k+ Minafi readers</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="relative">
      <div class="text-purple-100">
        <svg class="absolute" preserveAspectRatio="none" viewBox="0 0 2600 131.1">
          <path d="M0 0L2600 0 2600 100 0 0z" fill="currentColor"></path>
        </svg>
      </div>
      <div class="border-t rounded-t-none border-gray-400 bg-purple-200 rounded text-left p-4 pb-2 md:px-4 md:py-4 text-base text-center font-semibold">
        <a class="text-purple-800 flex items-center justify-center no-underline rounded hover:shadow hover:bg-white p-3 flex-row md:mt-4" href='/calculators'>
          <minicon icon='user-astronaut-regular' size='6' class='align-middle mr-1 text-purple-500'></minicon>
          <span>See all calculators</span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import Minicon from '~/components/utils/minicon.vue'
import Icon from '~/components/utils/icon.vue'
export default {
  components: {
    Icon,
    Minicon
  }
}
</script>
