import SparkElement from './spark-element'


class SparkSelect extends SparkElement {
  bind() {
    this.addChangeListeners()
    this.setSelectedIndex()
    this.resize()
  }

  createInputElement() {
  }

  addChangeListeners() {
    this.el.addEventListener('change', this.selectChanged.bind(this))
  }

  selectChanged() {
    this.syncData()
  }

  // Resize this select to the selected value
  resize() {
    var selectedText = this.el.options[this.el.selectedIndex].text
    var sizeEl = document.createElement('select')
    sizeEl.classList.add('spark-element--select')
    sizeEl.classList.add('invisible')

    var sizeOption = document.createElement('option')
    sizeOption.text = selectedText
    sizeEl.appendChild(sizeOption)

    document.body.appendChild(sizeEl)
    var width = sizeEl.getBoundingClientRect().width
    this.el.style.width = `${width+5}px`
    sizeEl.remove()
  }

  syncData() {
    this.data = this.el.value
    this.setSelectedIndex()
    this.resize()
  }

  update() {
    this.el.value = this._data
    this.setSelectedIndex()
    this.resize()
  }

  setSelectedIndex() {
    if(this.el.options[this.el.selectedIndex].value != this._data) {
      for(var i=0; i<this.el.options.length; i++) {
        if(this.el.options[i].value == this._data) {
          this.el.options[i].selected = true
          this.el.value = this._data
        }
      }
    }
  }

  unbindAll() {
    this.el.removeEventListener('change', this.selectChanged.bind(this))
  }
}

export default SparkSelect
