import * as d3 from '~/app/custom-d3'
import 'intersection-observer'
import scrollama from 'scrollama'
import SpViz from '~/components/viz/sp-viz'
import { every } from '~/app/utils/every'
import { isEmpty } from '~/app/utils/isEmpty'
import Finance from 'financejs'
const finance = new Finance()

let defaultGuide = {
  investment: 1000, // 2
  years: 30,
  returnsAfterXYears: 2400
}

function updateFields(guide) {
  console.log('updateFields called')
  guide.returnsAfterXYears = finance.FV(9, guide.investment, guide.years)
  return guide
}

export default class Sp500 {
  constructor(post) {
    console.log('Sp500 - constructor')
    this.post = post
    window.spWatcher = this.post.$store.watch(this.watchedFields, this.stateChanged, { deep: true })
    if(isEmpty(window.data.interactive.sp500)) {
      // Trigger initial state through changing the fields
      this.reset()
    }
    else {
      // Manually trigger state change with current state
      this.stateChanged(null, this.post.$store.getters['interactive/getGuideFields']('sp500'))
    }

    this.scroller = scrollama();

    this.scrolly = d3.select('#scroll');
    this.figure = this.scrolly.select(".graph");
    this.article = this.scrolly.select("article");
    this.steps = this.article.selectAll(".step");

    this.viz = new SpViz(this.figure)

    // setup the instance, pass callback functions
    this.scroller
      .setup({
        step: '.step',
        offset: 0.4,
        // debug: true
      })
      .onStepEnter(this.handleStepEnter.bind(this))
      .onStepExit(this.handleStepExit.bind(this))

    // setup resize event
    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize()
  }

  watchedFields(state) {
    return state.interactive.sp500
  }

  reset() {
    var currentGuide = this.post.$store.getters['interactive/getGuideFields']('sp500')

    for(var key in defaultGuide) {
      currentGuide[key] = defaultGuide[key]
    }

    this.stateChanged(null, updateFields(currentGuide))
  }

  stateChanged(oldGuide, guide) {
    if(this.updating) { return true }
    this.updating = true

    var changedGuide = updateFields(guide)
    if(every(changedGuide, (x) => { return x!== undefined})) {
      window.store.dispatch('interactive/setGuideFields', { namespace: 'sp500', guide: changedGuide }).then(() => {
        this.updating = false
      })
    } else {
      if(window.location.pathname == 'sp500') {
        alert('Ack, something went wrong with our calculations that caused some errors. I\'ve reset your settings to keep things working. Sorry about that!')
        this.reset()
      } else {
        // unwatch
        window.spWatcher()
      }
    }
  }

  handleResize() {
    // 1. update height of step elements
    var stepH = Math.floor(window.innerHeight * 0.75);
    this.steps.style("height", stepH + "px");

    // 3. tell scrollama to update new element dimensions
    this.scroller.resize();
    this.viz.resize();
  }

  handleStepEnter(response) {
    console.log('handleStepEnter', response)

    // add color to current step only
    this.steps.classed("is-active", function(d, i) {
      return i === response.index;
    });

    // update graphic based on step
    this.viz.enter(response.index, response.direction)
    // this.figure.select("p").text(response.index + 1);
  }

  handleStepExit(response) {
    console.log('handleStepExit', response)
    this.viz.exit(response.index, response.direction)
  }
}
