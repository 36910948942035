import SparkInput from '../input'
import AutoNumeric from 'autonumeric'

const defaultSettings = {
  watchExternalChanges: true
 }

 const OPTIONS = {
   'percentageUS1decPos': {
     currencySymbol: '',
     currencySymbolPlacement: 'p',
     decimalCharacter: '.',
     decimalPlaces: '1',
     digitGroupSeparator: ',',
     minimumValue: 0,
     negativePositiveSignPlacement: 'r',
     rawValueDivisor: 100,
     suffixText: '%',
     wheelStep: 0.0001
   },
   'percentageUS0decPos': {
     currencySymbol: '',
     currencySymbolPlacement: 'p',
     decimalCharacter: '.',
     decimalPlaces: '0',
     digitGroupSeparator: ',',
     minimumValue: 0,
     negativePositiveSignPlacement: 'r',
     rawValueDivisor: 100,
     suffixText: '%',
     wheelStep: 0.001
   },
   'year': {
     decimalPlaces: '0',
     digitGroupSeparator: ''
   }
 }



class SparkNumber extends SparkInput {
  setupElement(input) {
    input.value = this._data || 0
    input.type = 'text'
    this.createWrappedElement(input)
  }

  createWrappedElement(input) {
    var numberOptions = this.options.number || {}

    // Presets from AutoNumeric
    if(this.options.numberOption) {
      var numberOptions = this.options.numberOption.split(",")
      var predefinedOptions = {}, newOptionName, newOption
      for(var i=0; i<numberOptions.length; i++) {
        newOptionName = numberOptions[i]
        newOption = AutoNumeric.getPredefinedOptions()[newOptionName] || OPTIONS[newOptionName] || {}
        predefinedOptions = {...predefinedOptions, ...newOption}
      }
    } else {
      var predefinedOptions = {}
    }

    var numericSettings = {...defaultSettings, ...predefinedOptions, ...numberOptions}

    this.wrappedInput = new AutoNumeric(input, numericSettings)
  }

  set data(newData) {
    if(this._data != +newData) {
      this._data = +newData
      this.input.value = this._data
      this.dispatchChange()
    }
  }

  syncData() {
    if(this.input.value.length > 0) {
      var newValue = +this.wrappedInput.rawValue
      if(isNaN(newValue)) {
        this.input.classList.add('interactive-field--error')
      } else {
        this.input.classList.remove('interactive-field--error')
        this.data = newValue
      }
    }
  }
}

export default SparkNumber
