import SparkNumber from './number'

import {scalePow} from 'd3-scale'

class SparkNumberSlider extends SparkNumber {
  setupElement(input) {
    input.value = this._data || 0
    input.type = 'text'
    this.createWrappedElement(input)

    this.createRangedSlider()
  }

  createRangedSlider() {
    this.rangeSlider = document.createElement('input')
    this.rangeSlider.step = this.options.step || 1
    this.rangeSlider.type = 'range'

    var max = 100

    if(this.options.median) {
      this.scale = scalePow().domain([0,100])
                             .range([this.options.min, this.options.max])
                             .exponent(2)
      this.rangeSlider.min = 0
      this.rangeSlider.max = 100
      this.rangeSlider.step = 1
      this.rangeSlider.value = this.scale.invert(this._data)
      max = this.options.max
    } else {
      if(this.options.min) {
        if(typeof(this.options.min) == 'string') {
          this.rangeSlider.min = this.context.$store.getters['interactive/getGuideField'](this.namespace, this.options.min)
          this.watchField(this.options.min, (newValue) => {
            this.rangeSlider.min = newValue

            if(this._data < this.rangeSlider.min) {
              this.rangeSlider.value = this.rangeSlider.min
              this._data = this.rangeSlider.min
              this.update()
            }
          })
        } else {
          this.rangeSlider.min = this.options.min
        }
      }
      if(this.options.max) {
        if(typeof(this.options.max) == 'string') {
          this.rangeSlider.max = this.context.$store.getters['interactive/getGuideField'](this.namespace, this.options.max)
          max = this.rangeSlider.max
          this.watchField(this.options.max, (newValue) => {
            this.rangeSlider.max = newValue

            if(this._data > this.rangeSlider.max) {
              this.rangeSlider.value = this.rangeSlider.max
              this._data = this.rangeSlider.max
              this.update()
            }
          })
        } else {
          this.rangeSlider.max = this.options.max
          max = this.options.max
        }
      }

      this.rangeSlider.value = this._data
    }

    // Set the max length of the input (and the range) based on the maximum value allowed
    var originalValue = this._data
    this.input.value = max
    this.input.size = this.input.value.length + 1
    this.input.value = originalValue


    this.el.append(this.rangeSlider)

    this.rangeSlider.addEventListener('input', this.rangeChanged.bind(this))
    this.rangeSlider.addEventListener('mouseup', this.focusInput.bind(this))
    this.rangeSlider.addEventListener('input', this.syncSize.bind(this))
  }

  get rangeValue() {
    if(this.scale) {
      if(this.options.step) {
        return Math.round((this.scale(this.rangeSlider.value) / this.options.step))*this.options.step
      } else {
        return this.scale(this.rangeSlider.value)
      }
    } else {
      return +this.rangeSlider.value
    }
  }

  rangeChanged() {
    if(+this.rangeValue != this._data) {
      this.input.value = this.rangeValue
      this.data = this.rangeValue
    }
  }

  focusInput() {
    this.input.focus()
  }

  // Disable syncing for ranged sliders since it impacts positioning
  syncSize() {
    // do nothing
  }
}

export default SparkNumberSlider
