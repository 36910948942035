<template>
  <div class="relative" @mouseenter="open" @mouseleave="close">

    <div role="button" class="md:inline-block select-none" @click="toggle">
      <slot></slot>
    </div>

    <div v-if="isOpen" class="dropdown-items-wrapper md:absolute md:right-0 md:z-50 md:block">
      <slot name="dropdown-items" class='block'></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open() {
      if(!this.isMobile()) {
        this.isOpen = true
      }
    },
    close() {
      if(!this.isMobile()) {
        this.isOpen = false
      }
    },
    toggle(e) {
      if(this.isMobile()) {
        e.preventDefault()
        this.isOpen = !this.isOpen
      }
    },
    isMobile() {
      return window.innerWidth < 768
    }
  }
}
</script>
