import Spark from './src/spark'

export default {
  beforeMount(el, binding, vnode) {
    var options = {},
        key,
        value,
        elementName = binding.arg

    if(typeof binding.value != 'object') {
      throw('v-spark error: You must pass an object object with at least a "val" and "key".')
    }

    options = binding.value
    value = binding.value.val
    key = binding.value.key
    if(binding.value.type) {
      elementName = `${elementName}.${binding.value.type}`
    }


    el.classList.add('spark')
    el.classList.add(`spark-element--${elementName.replace(/\./g, '-')}`)

    if(document.querySelector("[data-v-spark-namespace]")) {
      options.namespace = document.querySelector("[data-v-spark-namespace]").dataset.vSparkNamespace
    } else {
      options.namespace = options.namespace || 'guide'
    }

    var SparkClass = Spark.element(elementName)
    el._spark = new SparkClass(el, key, value, binding.instance, options)
  },

  unmounted(el) {
    el._spark.unbind()
  }
}
