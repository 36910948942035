import * as Calculator from '~/app/calculator'
import Finance from 'financejs'
import { every } from '~/app/utils/every'
import { isEmpty } from '~/app/utils/isEmpty'

const currentMonth = new Date().getMonth()
const finance = new Finance()

let defaultGuide = {
  // 1
  exampleClick: false,
  age: 30,
  ageMonths: 0,
  yearlyIncome: 50000,
  yearlySavings: 10000,
  marketGrowth: 0.07,
  marketGrowthAfterInflation: 0.07-0.02,
  monthBorn: 0,
  inflationRate: 0.02,

  // 1-calculated
  savingsRate: 0.20,

  // 3
  gender: 'undisclosed',
  race: 'undisclosed',
  phase: 1,
  childrenCount: 0,
  goalReAge: 50,
  networth: 5000,
  fiStash: 1000000,

  // 4
  wr: 0.04,

  storeCookies: true,
  emailDownload: 0,
  yearlySpending: 40000,
  retirementSpendingPercent: 0.8,
  spendingReductionPercent: 0.10,
  eirIncomePercent: 0.10,
  monthlyIncome: 0,
  retirementMonthlyIncome: 0,
  payIncreasePercent: 0.02
}

function calcFiPhase(goalReAge, age, ageMonths, yearsUntilFi) {
  var calcYearsUntilFi = goalReAge - age - (ageMonths/12),
      diff = yearsUntilFi - calcYearsUntilFi;

  if(diff <= -1) {
    return 'ahead';
  } else if(diff > -1 && diff < 1) {
    return 'close';
  } else if(diff >= 1) {
    return 'behind';
  }
}

function updateFields(guide) {
  // Part 1-
  guide.nextAge = guide.age + 1
  guide.savingsRate = guide.yearlySavings / guide.yearlyIncome
  guide.monthsUntilBirthday = (currentMonth > guide.monthBorn) ? (12 - currentMonth) : (guide.monthBorn - currentMonth)
  guide.ageMonths = 11 - guide.monthsUntilBirthday
  guide.ageFull = guide.age + (guide.ageMonths/12)

  if((guide.yearlyIncome - guide.yearlySavings) < 0) {
    guide.impliedYearlySpending = guide.yearlyIncome
  } else {
    guide.impliedYearlySpending = guide.yearlyIncome - guide.yearlySavings;
  }
  var possibleYearlySpending = (guide.impliedYearlySpending < 0) ? guide.yearlyIncome : guide.impliedYearlySpending;
  guide.impliedRetirementStashNeeded = Calculator.stash(possibleYearlySpending, guide.wr);
  guide.yearsUntilFiOnlySR = Math.floor(Calculator.timeUntilFi(guide.yearlySavings, guide.impliedRetirementStashNeeded, 0, guide.marketGrowth))
  guide.marketGrowthAfterInflation = guide.marketGrowth - guide.inflationRate

  // Part 2
  guide.retirementYearlySpending = guide.yearlySpending * guide.retirementSpendingPercent
  guide.fiStash = Calculator.stash(guide.retirementYearlySpending, guide.wr)

  if(guide.yearlySavings > 0) {
    guide.yearlyNetChange = guide.yearlySavings
  } else {
    guide.yearlyNetChange = guide.yearlyIncome - guide.retirementYearlySpending
  }


  guide.missingRetirementIncome = guide.fiStash - guide.networth
  if(guide.missingRetirementIncome < 0) {
    guide.missingRetirementIncome = 0
  }
  guide.yearsUntilFi = Calculator.timeUntilFi(guide.yearlySavings, guide.fiStash, guide.networth, guide.marketGrowth) || 0

  guide.fiPhase = calcFiPhase(guide.goalReAge, guide.age, guide.ageMonths, guide.yearsUntilFi)
  guide.fiAge = guide.age + (guide.ageMonths/12) + guide.yearsUntilFi

  // Reducing Spending
  // guide.spendingReductionPercent = 1 - guide.retirementSpendingPercent
  guide.impliedSpendingReductionYearlySavings = guide.yearlySpending * guide.spendingReductionPercent
  guide.impliedSpendingReductionYearlySavingsTotal = guide.impliedSpendingReductionYearlySavings + guide.yearlySavings
  guide.impliedSpendingReductionYearlySpending = guide.retirementYearlySpending - guide.impliedSpendingReductionYearlySavings
  guide.spendingReductionStash = Calculator.stash(guide.impliedSpendingReductionYearlySpending, guide.wr)
  guide.spendingReductionYearsUntilFi = Calculator.timeUntilFi(guide.impliedSpendingReductionYearlySavingsTotal, guide.spendingReductionStash, guide.networth, guide.marketGrowth)

  // Part 4
  guide.fiStash3 = Calculator.stash(guide.retirementYearlySpending, 0.03)
  guide.fiStash4 = Calculator.stash(guide.retirementYearlySpending, 0.04)
  guide.fiStashDifference = guide.fiStash3 - guide.fiStash4


  // Part 5
  guide.yearsOfFiNoInvestment = guide.fiStash / guide.retirementYearlySpending;
  guide.fiTotalSpending = Calculator.totalSpending(guide.stash, guide.yearlySpending, guide.marketGrowth, guide.fiAge)
  guide.investmentYearsDifference = (guide.fiTotalSpending - guide.fiStash) / guide.retirementYearlySpending
  guide.retirementYearlySpendingWithInflation = finance.FV(guide.inflationRate*100, guide.retirementYearlySpending, guide.yearsUntilFi);
  guide.retirementYear = guide.yearsUntilFi + (new Date()).getFullYear();


  guide.retirementYearlySpending2 = guide.retirementYearlySpending * (1 + guide.inflationRate)
  guide.inflationRateIncrease2 = guide.retirementYearlySpending2 - guide.retirementYearlySpending
  guide.retirementYearlySpending3 = guide.retirementYearlySpending2 * (1 + guide.inflationRate)
  guide.inflationRateIncrease3 = guide.retirementYearlySpending3 - guide.retirementYearlySpending2
  guide.retirementYearlySpending4 = guide.retirementYearlySpending3 * (1 + guide.inflationRate)
  guide.inflationRateIncrease4 = guide.retirementYearlySpending4 - guide.retirementYearlySpending3
  guide.retirementYearlySpending5 = guide.retirementYearlySpending4 * (1 + guide.inflationRate)
  guide.inflationRateIncrease5 = guide.retirementYearlySpending5 - guide.retirementYearlySpending4

  // Part 6
  // Reduce spending
  guide.spendingReductionYearsEarlier = guide.yearsUntilFi - guide.spendingReductionYearsUntilFi;
  guide.spendingReductionStashDifference = guide.fiStash - guide.spendingReductionStash;

  // Earn in retirement
  guide.eirIncomeAfterRetirement = guide.eirIncomePercent * guide.retirementYearlySpending;
  guide.earnInRetirementStashNeeded = Calculator.stash(guide.retirementYearlySpending - guide.eirIncomeAfterRetirement, guide.wr);
  guide.eirTimeUntilFi = Calculator.timeUntilFi(guide.yearlySavings, guide.earnInRetirementStashNeeded, guide.networth, guide.marketGrowth)
  guide.eirTimeSooner = guide.yearsUntilFi - guide.eirTimeUntilFi;

  // Increase Income
  guide.payIncreaseTimeUntilFi = Calculator.timeUntilFi(guide.yearlySavings, guide.fiStash, guide.networth, guide.marketGrowth+guide.payIncreasePercent);
  guide.payIncreaseSooner = guide.yearsUntilFi - guide.payIncreaseTimeUntilFi;

  // Earn & increase income
  var combinedStashNeeded = Calculator.stash(guide.retirementYearlySpending - guide.eirIncomeAfterRetirement - guide.impliedSpendingReductionYearlySavings, guide.wr);
  guide.allSoonerYears = Calculator.timeUntilFi(guide.impliedSpendingReductionYearlySavingsTotal, combinedStashNeeded, guide.networth, guide.marketGrowth);
  guide.allSoonerYearsEarly = guide.yearsUntilFi - guide.allSoonerYears;
  if(guide.yearsUntilFi > 0) {
    guide.allSoonerPercent = guide.allSoonerYearsEarly / guide.yearsUntilFi;
  } else {
    guide.allSoonerPercent =  0;
  }

  // t.stop()
  return guide
}

export default class FireGuide {
  constructor(store) {
    this.store = store
    this.updating = false
    window.fireWatcher = this.store.watch(this.watchedFields, this.stateChanged.bind(this), { deep: true })

    if(isEmpty(window.data.interactive.fire)) {
      // Trigger initial state through changing the fields
      this.reset()
    }
    else {
      // Manually trigger state change with current state
      this.stateChanged(null, this.store.getters['interactive/getGuideFields']('fire'))
    }

    this.reset = () => {
      this.resetGuide()
    }
  }

  resetGuide() {
    var currentGuide = this.store.getters['interactive/getGuideFields']('fire')

    for(var key in defaultGuide) {
      currentGuide[key] = defaultGuide[key]
    }

    this.stateChanged(null, updateFields(currentGuide))
  }

  watchedFields(state) {
    return state.interactive.fire
  }

  stateChanged(oldGuide, guide) {
    if(this.updating) { return true }
    this.updating = true

    var changedGuide = updateFields(guide)
    if(every(changedGuide, (x) => { return x!== undefined})) {
      window.store.dispatch('interactive/setGuideFields', { namespace: 'fire', guide: changedGuide }).then(() => {
        this.updating = false
      })
    } else {
      if(window.location.pathname == 'interactive-guide-early-retirement-financial-independence') {
        alert('Ack, something went wrong with our calculations that caused some errors. I\'ve reset your settings to keep things working. Sorry about that!')
        this.reset()
      } else {
        // unwatch
        window.fireWatcher()
      }
    }
  }
}
