import SparkElement from './spark-element'

class SparkText extends SparkElement {
  update() {
    var result = this._data
    for(var i=0; i<this.formats.length; i++) {
      result = this.formats[i](result, this.options, this.el)
    }

    this.el.innerHTML = result
  }
}

export default SparkText
