import SparkElement from './spark-element'

class SparkCheckbox extends SparkElement {
  bind() {
    this.createInputElement()
    this.addChangeListeners(this.input)
  }

  createInputElement() {
    this.input = document.createElement('input')
    this.setupElement(this.input)
    this.input.value = this._data
    this.input.classList.add('spark-element--checkbox-el')

    this.el.prepend(this.input)
    this.update()
  }

  setupElement(input) {
    input.type = 'checkbox'
  }

  addChangeListeners(inputEl) {
    inputEl.addEventListener('change', this.dataChanged.bind(this))
  }

  dataChanged() {
    this.syncData()
  }

  syncData() {
    this.data = this.input.checked ? 'yes' : 'no'
    this.input.value = this.data
  }

  update() {
    this.input.checked = this.data == 'yes'
    this.input.value = this.data
  }

  unbindAll() {
    this.input.removeEventListener('change', this.dataChanged.bind(this))
  }
}

export default SparkCheckbox
