<template>
  <div class='inline hover:cursor-pointer' @click.prevent='markAsRead'>
    <span v-if='completed' class='inline-block' v-tooltip.right='`Read on ${completed_at}`'>
      <minicon icon='badge-check-solid' size='6' class='text-green-600'></minicon>
    </span>
    <a href='#' class='inline-block' v-else v-tooltip.right='"Mark as read"'>
      <minicon icon='badge-check-regular' size='6' class='text-gray-600 hover:text-green-600'></minicon>
    </a>
    <slot v-if='!loaded'></slot>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Minicon from '~/components/utils/minicon.vue'

export default {
  name: 'activity-badge',
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      completed: 'post.activity.completed',
      completed_at: 'post.activity.completed_at_blog',
      user: 'user'
    })
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    markAsRead() {
      if(this.user.guest) {
        this.$store.dispatch('app/showLogin')
      } else if(!this.completed) {
        this.$store.dispatch('post/progress', { progress: 100 })
      }
    }
  }
}
</script>
