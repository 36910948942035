<template>
  <div class='card bg-white sidebar px-8 py-8 bg-gradient-to-b from-white via-white to-green-100' v-if='!completed'>
    <div class='text-center'>
      <p class='text-center'><minicon icon='envelope-regular' size='24' class="text-green-500"></minicon></p>

      <p class='reset text-4xl font-bold mb-6'>The Minimal Investor Course</p>
      <p class='reset text-gray-700 text-lg'>
        I'll send you an <b>email a week for 10-weeks</b>.
        By the end of this <b>free</b> hands-on course, you'll know enough to <b>invest for the rest of your life</b>.
      </p>

      <transition name='fade-up'>
        <div>
          <div class='form-content mt-6' v-if='!user.guest'>
            <p class='adam-head reset leading-normal text-gray-700 text-lg'>
              Hey <b>{{user.name}}</b>! Since you're already a Minafi member, you can read this course at any time!
            </p>
          </div>
          <div class='form-content mt-6' v-else-if='submitted'>
            <p class='mt-8'>
              <span class='flex'>
                <a class='reset button button-green button-flex justify-center items-center mr-2' href='/courses/the-minimal-investor'>
                  <minicon icon='book-open-regular' size='6' class="text-white flex-shrink"></minicon>
                  <span class='flex-shrink ml-2 flex items-center'>
                    <minicon icon='book-open-regular' size='6' class="text-white mr-2"></minicon>                    
                    <span>Read this course on Minafi</span>
                  </span>
                </a>
              </span>
            </p>
          </div>
          <div v-else class='mt-2'>
            <form @submit.prevent='submit'>
              <div class='field mb-6'>
                <label for='form-minimal-investor-course-email' class='sr-only'>Email</label>
                <input id='form-minimal-investor-course-email' type='email' placeholder="What's your email?" class='form-text' v-model='email' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.email'>{{errors.email}}</p>
              </div>
              <div class='field mb-6'>
                <label for='form-minimal-investor-course-name' class='sr-only'>Name</label>
                <input id='form-minimal-investor-course-name' type='text' class='form-text' placeholder="What's your name?" v-model='name' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.name'>{{errors.name}}</p>
              </div>
              <div class='field flex content-center flex-row ml-1'>
                <input type='checkbox' id='mic-gdpr_consent' value='granted' v-model='gdpr_consent' class='mr-2 p-2 leading-tight transform scale-150 self-center mb-2' />
                <label for='mic-gdpr_consent' class='form-label ml-2' v-html='gdpr_consent_message'></label>
              </div>
              <p class='text-red-500 text-base text-left mt-1 mb-2' v-if='errors.consent'>{{errors.consent}}</p>
              <div class='mt-6'>
                <button type='submit' class='button button-green button-flex justify-center items-center' aria-label='Submit Form'>
                  <minicon v-if='submitting' icon='spinner-regular' size='6' class="text-white flex-shrink"></minicon>
                  <minicon v-else icon='envelope-regular' size='6' class="text-white flex-shrink"></minicon>
                  <span class='flex-shrink ml-2'>Learn to Invest For Free</span>
                </button>
              </div>
            </form>
          </div>

          <div class='submitted-confirmation' v-if='submitted'>
            <div class='adam-head bg-green-100 mt-8 py-4 px-2 rounded'>
              <p class='reset text-gray-800 my-2 text-xl font-bold'>Welcome to the Minimal Investor Course!</p>
              <p class='reset text-gray-700 text-lg text-center'>
                Confirm your email and I'll send out your first lesson.<br/><br/>
                I truely hope you enjoy the course! If you have any questions, comments or feedback on it, just reply to any email. - Adam
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { getCookie } from '~/app/cookie'
import * as client from '~/app/client'
import Minicon from '~/components/utils/minicon.vue'

export default {
  props: ['source'],
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      'subscriptions': 'user.subscriptions',
      'user': 'user'
    }),
    completed() {
      return this.subscriptions.indexOf('minimalinvestor') !== -1
    }
  },
  data() {
    return {
      email: '',
      name: '',
      gdpr_consent: false,
      gdpr_consent_message: "I consent to receive information via email. (Read our <a href='/privacy-policy' class='link link-grey' target='_blank'>privacy policy</a> for more information).",
      submitting: false,
      submitted: false,
      errors: {
        name: null,
        email: null,
        consent: null
      }
    }
  },
  methods: {
    submit() {
      if(!this.valid()) { return; }

      // Magic to submit this
      this.submitting = true
      this.$el.classList.add('form-submitting')

      client.post('/account/subscribe', {
        subscription: 'minimalinvestor',
        source: this.source,
        fields: {
          email: this.email,
          name: this.name,
          gdpr: this.gdpr_consent,
          consent_message: this.gdpr_consent_message
        }
      }).then((response) => {

        client.post('/account/subscribe', {
          subscription: 'newsletter',
          send_in: 60,
          fields: {
            email: this.email,
            name: this.name,
            gdpr: this.gdpr_consent,
            consent_message: this.gdpr_consent_message,
            silent: true
          }
        }).then((response) => {
          this.submitting = false
          if(response.message) {
            this.errors.consent = "Uh oh! There was a problem. Could you give it another try?"
          } else {
            this.submitted = true
            this.$el.classList.remove('form-submitting')
            this.$el.classList.add('form-submitted')
          }
        })
      })
    },
    valid() {
      if(!this.name) {
        this.errors.name = 'Please enter a user name.'
      } else {
        this.errors.name = null
      }

      if(!this.validEmail(this.email)) {
        this.errors.email = 'Please enter a valid email address.'
      } else {
        this.errors.email = null
      }

      if(!this.gdpr_consent) {
        this.errors.consent = 'You really need to agree to these terms. Legal people love them.'
      } else {
        this.errors.consent = null
      }

      return !(this.errors.email ||  this.errors.name ||  this.errors.consent)
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>
