export function stash(spending, wr) {
  return Math.ceil(100*spending * (100/(wr*100)))/100
}

export function timeUntilFi(yearlySavings, stashNeeded, networth, marketGrowth) {
  var fv = yearlySavings + (stashNeeded * marketGrowth)
  var pv = yearlySavings + (networth * marketGrowth)

  var j = Math.log(fv/pv)
  var d = Math.log(1+marketGrowth)

  if(j < 0) { return 0 }
  return j/d
}

export function yearsUntilFi(sr, marketRate, wr) {
  var savings = 100*sr,
      spending = 100 - savings,
      stashNeeded = spending * (1/wr),
      growth = marketRate;

  var fv = savings + (stashNeeded * growth);
  var pv = savings;

  var j = Math.log(fv/pv);
  var d = Math.log(1 + growth);

  if(j < 0) { return 0; }
  return j/d;
}

export function totalSpending(stash, yearlySpending, growthRate, fiAge) {
  var spending = 0;

  if(+fiAge > 100) { return 0; }

  for(var i=0; i<(100-(+fiAge)); i++) {
    spending += +yearlySpending;
    stash = +stash + (+stash * +growthRate) - +yearlySpending;
    yearlySpending = +yearlySpending + (+yearlySpending * 0.03);
    if(stash < 0) { break; }
  }

  return spending;
}
