import SparkElement from './spark-element'

class SparkRadio extends SparkElement {
  bind() {
    this.createInputElement()
    this.addChangeListeners(this.input)
  }

  createInputElement() {
    this.input = document.createElement('input')
    this.setupElement(this.input)
    this.input.value = this._data
    this.checkedValue = this.options.value
    this.input.name = this.key
    this.input.classList.add('spark-element--radio-el')
    this.update()

    this.el.prepend(this.input)
  }

  setupElement(input) {
    input.type = 'radio'
  }

  addChangeListeners(inputEl) {
    inputEl.addEventListener('change', this.dataChanged.bind(this))
  }

  dataChanged() {
    this.syncData()
  }

  syncData() {
    this.data = this.input.checked ? this.checkedValue : null
  }

  update() {
    this.input.checked = this.data == this.checkedValue
  }

  unbindAll() {
    this.input.removeEventListener('change', this.dataChanged.bind(this))
  }
}

export default SparkRadio
