import numeral from 'numeral'

const FORMATS = {
  exact: '0,0.00',
  dollar: '0,0'
}

export default function currency(input, options) {
  var symbol = options.symbol || '$',
      format = FORMATS[options.formatStyle] || FORMATS.exact
  return numeral(input).format(`${symbol}${format}`)
}
