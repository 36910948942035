import * as Elements from './elements'
import * as Formats from './formats'

class Spark {

  // This will allow for creating any element that matches
  // an exported name from lib/elements/index..js
  //
  // ex:
  //   v-spark:number
  //   v-spark:text
  //   v-spark:text.currency
  static element(elementName) {
    return Elements[elementName.replace(/\./g, '_')]
  }

  //
  // ex:
  //   v-spark:text='{val: guide.savingsRate, key: "savingsRate", format: "percent" }
  static format(formatName) {
    return Formats[formatName.replace('.', '_')]
  }
}

export default Spark
