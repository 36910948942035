<template>
  <div class='form-minimal-investor-corner z-10 bg-gradient-to-b from-white via-white to-green-100 shadow-lg rounded container-slim lg:rounded-b-none lg:w-96 lg:fixed lg:right-0 lg:bottom-0 lg:mr-8' :class='{"form-minimal-investor-corner--expanded": isExpanded}' v-if='!completed'>

    <a href='#' @click.prevent='toggle' class='flex justify-between relative items-center rounded rounded-b-none bg-green-500 lg:hover:bg-green-600 text-white font-bold py-2 px-4 cursor-text lg:cursor-pointer'>
      <span class='text-lg'>Minafi's Free Investing Course</span>
      <minicon :icon='expandIcon' size='4' class="text-green-100 hidden lg:inline-block"></minicon>
    </a>

    <div class='p-4'>
      <transition name='fade-up' mode='out-in'>
        <div class='form-content' v-if='!submitted'>
          <p class='text-center'><minicon icon='envelope-regular' size='16' class="text-green-500 mb-1"></minicon></p>
          <p class='text-gray-700 text-base text-center'>
            Join my <a href='/courses/the-minimal-investor' class='link link-green font-semibold'>free course</a> &amp; you'll receive <br/>
            an <b>email a week for 10-weeks</b>.
          </p>

          <p class='text-gray-700 text-base mt-2 text-center'>
            By the end, you'll know how <br/>
            to invest <b>for the rest of your life</b>.
          </p>
          <form @submit.prevent='submit'>
            <div class='p-4'>
              <div class='field mb-4'>
                <label class='text-sm font-semibold text-gray-700' for='form-minimal-investor-course-email'>Email</label>
                <input id='form-minimal-investor-course-email' type='email' class='form-text' placeholder='you_are@awesome.com' v-model='email' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.email'>{{errors.email}}</p>
              </div>
              <div class='field mb-4'>
                <label class='text-sm font-semibold text-gray-700' for='form-minimal-investor-course-name'>Name</label>
                <input id='form-minimal-investor-course-name' type='text' class='form-text' placeholder='And smart too!' v-model='name' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.name'>{{errors.name}}</p>
              </div>
              <div class='field flex content-center flex-row ml-1'>
                <input type='checkbox' id='mic-gdpr_consent' value='granted' v-model='gdpr_consent' class='mr-2 p-2 leading-tight transform scale-150 self-center mb-2' />
                <label for='mic-gdpr_consent' class='form-label ml-2' v-html='gdpr_consent_message'></label>
              </div>
              <p class='text-red-500 text-base text-left mt-1 mb-2' v-if='errors.consent'>{{errors.consent}}</p>
            </div>
            <div class=''>
              <button type='submit' class='button-green rounded button-flex py-2 px-4 items-center justify-center' aria-label='Submit Form'>
                <minicon v-if='submitting' icon='spinner-regular' size='6' class="text-white flex-shrink"></minicon>
                <minicon v-else icon='envelope-regular' size='6' class="text-white flex-shrink"></minicon>
                <span class='flex-shrink ml-2 font-bold text-lg'>Learn to Invest For Free</span>
              </button>
            </div>
          </form>
        </div>

        <div v-else class='adam-head bg-green-100 mt-4 p-4 rounded'>
          <p class='reset text-gray-800 mb-2 font-bold text-lg'>Thanks for signing up!</p>
          <p class='reset text-gray-700 text-base text-center leading-tight font-semibold'>
            Confirm your email to receive the first lesson.
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { post } from '~/app/client'
import Minicon from '~/components/utils/minicon.vue'
import { scrollStart } from '~/app/utils/scrollStart'

export default {
  props: ['source'],
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      'subscriptions': 'user.subscriptions',
      'user': 'user'
    }),
    completed() {
      return !this.user.guest || (this.subscriptions.indexOf('minimalinvestor') !== -1) || this.isClosed
    },
    expandIcon() {
      return this.isExpanded ? 'times-regular' : 'angle-double-up-regular'
    }
  },
  data() {
    return {
      email: '',
      name: '',
      gdpr_consent: false,
      gdpr_consent_message: "The <a href='/privacy-policy' class='link link-green' target='_blank'>privacy policy</a> looks good.",
      submitting: false,
      submitted: false,
      isExpanded: false,
      isClosed: false,
      errors: {
        name: null,
        email: null,
        consent: null
      }
    }
  },
  mounted() {
    scrollStart(this.autoExpand)
  },
  methods: {
    autoExpand() {
      setTimeout(() => {
        this.isExpanded = true
      }, 3000)
    },
    toggle() {
      this.isExpanded = !this.isExpanded

      if(!this.isExpanded && this.submitted) {
        this.isClosed = true
      }
    },
    submit() {
      if(!this.valid()) { return; }

      // Magic to submit this
      this.submitting = true
      this.$el.classList.add('form-submitting')

      post('/account/subscribe', {
        subscription: 'minimalinvestor',
        source: this.source,
        fields: {
          email: this.email,
          name: this.name,
          gdpr: this.gdpr_consent,
          consent_message: this.gdpr_consent_message
        }
      }).then((response) => {

        post('/account/subscribe', {
          subscription: 'newsletter',
          send_in: 60,
          fields: {
            email: this.email,
            name: this.name,
            gdpr: this.gdpr_consent,
            consent_message: this.gdpr_consent_message,
            silent: true
          }
        }).then((response) => {
          this.submitting = false
          if(response.message) {
            this.errors.consent = "Uh oh! There was a problem. Could you give it another try?"
          } else {
            this.submitted = true
            this.$el.classList.remove('form-submitting')
            this.$el.classList.add('form-submitted')
          }
        })
      })
    },
    valid() {
      if(!this.name) {
        this.errors.name = 'Please enter a user name.'
      } else {
        this.errors.name = null
      }

      if(!this.validEmail(this.email)) {
        this.errors.email = 'Please enter a valid email address.'
      } else {
        this.errors.email = null
      }

      if(!this.gdpr_consent) {
        this.errors.consent = 'You really need to agree to these terms. Legal people love them.'
      } else {
        this.errors.consent = null
      }

      return !(this.errors.email ||  this.errors.name ||  this.errors.consent)
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>

<style>
@media (min-width: 992px) {
	 .form-minimal-investor-corner {
		 transform: translateY(92%);
		 transition: transform 0.25s ease-in-out;
	}
	 .form-minimal-investor-corner--expanded {
		 transform: translateY(0%);
	}
}
</style>
